<!-- components/HamburgerMenu.vue -->
<template>
  <div class="news-memu-container theme-4" :style="{ background: themeConfig.themeBaseColor }">
    <div class="news-menu-row" :style="{ background: themeConfig.themeBaseColor }">
      <div class="news-menu-name-row hands" @click="goto('home')">
        <div v-if="domainConfig?.icon" class="menu-icon-flex">
          <img v-lazy="domainConfig.icon" alt="">
        </div>
        <div class="site-name">
          {{ domainConfig.title }}
        </div>
      </div>

      <div v-show="isSearchOpen" class="search-container">
        <input v-model="query" type="text" placeholder="Search..." @keyup.enter="search">
        <button class="search-button" @click="search" />
      </div>

      <div class="news-menu-feature-row">
        <div class="search" @click="toggleState('isSearchOpen')">
          <IconComponent :rectAttributes="isSearchOpen ? clickedSearchRect : defaultSearchRect"
            :pathD="isSearchOpen ? clickedSearchPathD : defaultSearchPathD"
            :pathFill="isSearchOpen ? 'white' : 'white'" :pathStroke="isSearchOpen ? '' : 'white'"
            width="40" height="40" />
        </div>

        <div class="hamburger-flex" @click="toggleState('isOpen')">
          <IconComponent :rectAttributes="isOpen ? clickedHamburgerRect : defaultHamburgerRect"
            :pathD="isOpen ? clickedHamburgerPathD1 : defaultHamburgerPathD"
            :pathFill="isOpen ? 'white' : 'black'" width="40" height="40" />
        </div>
      </div>
    </div>

    <div class="menu" :class="{ 'menu-open': isOpen }"
      :style="{ background: themeConfig.mainColorType }">
      <ul>
        <li class="hands" @click="goto('home')">
          <a>Home</a>
          <div class="arrow" />
        </li>
        <li v-for="(data,i) in $store.state.sideBar" :key="`sidebar_${i}`" class="hands"
          @click="gotoItem(data.domain)">
          <a>{{ data.lable }}</a>
          <div class="arrow" />
        </li>
        <li class="hands" @click="goto('privacy')">
          <a>Privacy Policy</a>
          <div class="arrow" />
        </li>
        <li class="hands" @click="goto('terms')">
          <a>Terms of Use</a>
          <div class="arrow" />
        </li>
      </ul>
    </div>
    <div v-show="isOpen" class="popup-shadow" />
  </div>
</template>

<script>
import '@/css/news/menu-4.scss';
import IconComponent from '@/pages/components/IconComponent.vue';

export default {
  components: {
    IconComponent,
  },
  props: {
    themeConfig: {
      required: true,
      type: Object,
    },
    domainConfig: {
      required: true,
      type: Object,
    },
    host: {
      type: String,
      default: '',
    },
  },
  data () {
    return {
      isOpen: false,
      isSearchOpen: false,
      query: '',
    };
  },
  computed: {
    defaultSearchRect () {
      return {
        x: 1,
        y: 1,
        width: 38,
        height: 38,
        rx: 9,
        stroke: 'white',
        strokeWidth: 2,
        fill: 'none',
      };
    },
    clickedSearchRect () {
      return {
        x: 1,
        y: 1,
        width: 38,
        height: 38,
        rx: 9,
        stroke: 'white',
        strokeWidth: 2,
      };
    },
    defaultSearchPathD () {
      return 'M25.7323 24.142C25.7823 24.177 25.8313 24.216 25.8753 24.262L31.5393 29.925C31.9203 30.306 31.9263 30.945 31.5363 31.335C31.4441 31.4283 31.3343 31.5024 31.2134 31.5531C31.0925 31.6038 30.9627 31.63 30.8316 31.6304C30.7005 31.6308 30.5706 31.6052 30.4493 31.5552C30.3281 31.5052 30.218 31.4318 30.1253 31.339L24.4613 25.675C24.4174 25.6313 24.3779 25.5834 24.3433 25.532C22.5565 27.0019 20.3138 27.8038 18.0002 27.8C12.4772 27.8 8.00024 23.323 8.00024 17.8C8.00024 12.277 12.4772 7.80005 18.0002 7.80005C23.5232 7.80005 28.0003 12.277 28.0003 17.8C28.0003 20.207 27.1503 22.416 25.7323 24.142ZM18.0002 25.8C20.122 25.8 22.1568 24.9572 23.6571 23.4569C25.1574 21.9566 26.0003 19.9218 26.0003 17.8C26.0003 15.6783 25.1574 13.6435 23.6571 12.1432C22.1568 10.6429 20.122 9.80005 18.0002 9.80005C15.8785 9.80005 13.8437 10.6429 12.3434 12.1432C10.8431 13.6435 10.0002 15.6783 10.0002 17.8C10.0002 19.9218 10.8431 21.9566 12.3434 23.4569C13.8437 24.9572 15.8785 25.8 18.0002 25.8Z';
    },
    clickedSearchPathD () {
      return 'M10.3977 10.3977C10.9281 9.86742 11.7879 9.86742 12.3182 10.3977L20 18.0796L27.6819 10.3977C28.2122 9.86742 29.072 9.86742 29.6023 10.3977C30.1326 10.9281 30.1326 11.7879 29.6023 12.3182L21.9205 20L29.6023 27.6818C30.1326 28.2121 30.1326 29.0719 29.6023 29.6023C29.0719 30.1326 28.2121 30.1326 27.6818 29.6023L20 21.9205L12.3183 29.6023C11.7879 30.1326 10.9281 30.1326 10.3978 29.6023C9.86749 29.0719 9.86749 28.2121 10.3978 27.6818L18.0796 20L10.3977 12.3182C9.86742 11.7879 9.86742 10.9281 10.3977 10.3977Z';
    },
    defaultHamburgerRect () {
      return {
        width: 40,
        height: 40,
        rx: 10,
        fill: this.themeConfig.mainColorType,
      };
    },
    clickedHamburgerRect () {
      return {
        x: 1,
        y: 1,
        width: 38,
        height: 38,
        rx: 9,
        stroke: 'white',
        strokeWidth: 2,
      };
    },
    defaultHamburgerPathD () {
      return 'M9.6556 12.2004C9.6556 11.2799 10.4018 10.5337 11.3223 10.5337H28.4211C29.3416 10.5337 30.0878 11.2799 30.0878 12.2004C30.0878 13.1208 29.3416 13.867 28.4211 13.867H11.3223C10.4018 13.867 9.6556 13.1208 9.6556 12.2004ZM9.26672 20.2525C9.26672 19.332 10.0129 18.5858 10.9334 18.5858H29.0669C29.9874 18.5858 30.7336 19.332 30.7336 20.2525C30.7336 21.173 29.9874 21.9192 29.0669 21.9192H10.9334C10.0129 21.9192 9.26672 21.173 9.26672 20.2525ZM11.3223 25.8558C10.4018 25.8558 9.6556 26.602 9.6556 27.5225C9.6556 28.443 10.4018 29.1892 11.3223 29.1892H28.4211C29.3416 29.1892 30.0878 28.443 30.0878 27.5225C30.0878 26.602 29.3416 25.8558 28.4211 25.8558H11.3223Z';
    },
    clickedHamburgerPathD1 () {
      return 'M10.3977 10.3977C10.9281 9.86742 11.7879 9.86742 12.3182 10.3977L20 18.0796L27.6819 10.3977C28.2122 9.86742 29.072 9.86742 29.6023 10.3977C30.1326 10.9281 30.1326 11.7879 29.6023 12.3182L21.9205 20L29.6023 27.6818C30.1326 28.2121 30.1326 29.0719 29.6023 29.6023C29.0719 30.1326 28.2121 30.1326 27.6818 29.6023L20 21.9205L12.3183 29.6023C11.7879 30.1326 10.9281 30.1326 10.3978 29.6023C9.86749 29.0719 9.86749 28.2121 10.3978 27.6818L18.0796 20L10.3977 12.3182C9.86742 11.7879 9.86742 10.9281 10.3977 10.3977Z';
    },
    clickedHamburgerPathD2 () {
      return 'M36.5227 13.4773C35.8863 12.8409 34.8546 12.8409 34.2182 13.4773L25 22.6954L15.7819 13.4773C15.1455 12.8409 14.1138 12.8409 13.4774 13.4773C12.841 14.1137 12.841 15.1454 13.4774 15.7818L22.6955 25L13.4773 34.2182C12.8409 34.8546 12.8409 35.8863 13.4773 36.5227C14.1137 37.1591 15.1454 37.1591 15.7818 36.5227L25 27.3045L34.2183 36.5227C34.8546 37.1591 35.8864 37.1591 36.5228 36.5227C37.1592 35.8863 37.1592 34.8546 36.5228 34.2182L27.3046 25L36.5227 15.7818C37.1591 15.1454 37.1591 14.1137 36.5227 13.4773Z';
    },
  },
  methods: {
    gotoItem (domain) {
      this.isOpen = false;
      setTimeout(() => {
        this.$emit('selectMenuItem', domain);
      }, 300);
    },
    search () {
      if (this.query.trim() !== '') {
        console.log(this.query);
        this.isSearchOpen = false
        // this.$emit('keyWordSearch', this.query);
        // if(this.$route)
        console.log(this.$route.name);
        this.$router.push({ name: 'search', query: { keyword: this.query } });
        if (this.$route.name == 'search') {
          this.$emit('keyWordSearch', this.query);
        }
      }
    },
    toggleState (state) {
      if (state === 'isSearchOpen') {
        this.isSearchOpen = !this.isSearchOpen;
        if (this.isSearchOpen) {
          this.isOpen = false;
        }
      } else if (state === 'isOpen') {
        this.isOpen = !this.isOpen;
        if (this.isOpen) {
          this.isSearchOpen = false;
        }
      }
    },
    goto (where) {
      console.log(this.$route.path);
      this.isOpen = false;
      if (where == 'home') {
        this.$emit('setRequestDomainHome');
      }
      if (where == 'home' && this.$route.path == '/') {
        this.scrollToTop()
        return
      }
      this.$router.push({ name: where });
    },
    scrollToTop () {
      let topHeight =
        document.documentElement.scrollTop ||
        window.pageYOffset ||
        document.body.scrollTop;
      let speed = topHeight / 10 > 100 ? topHeight / 10 : 100;
      scrollBy(0, -speed);
      // 模拟鼠标向上滚动事件
      this.scrolldelay = setTimeout(this.scrollToTop, 50);
      // 清除滚动事件，避免无法向下移动
      if (topHeight === 0) {
        clearTimeout(this.scrolldelay);
        this.scrolldelay = null;
      }
    },
  },
};
</script>
