<template>
  <svg xmlns="http://www.w3.org/2000/svg" :width="width" :height="height" viewBox="0 0 50 50"
    fill="none">
    <rect :x="rectAttributes.x" :y="rectAttributes.y" :width="rectAttributes.width"
      :height="rectAttributes.height" :rx="rectAttributes.rx" :stroke="rectAttributes.stroke"
      :stroke-width="rectAttributes.strokeWidth" :fill="rectAttributes.fill" />
    <path :d="pathD" :fill="pathFill" :stroke="pathStroke" fill-rule="evenodd"
      clip-rule="evenodd" />
    <path v-if="extraPathD" :d="extraPathD" :fill="extraPathFill" fill-rule="evenodd"
      clip-rule="evenodd" />
  </svg>
</template>

<script>
export default {
  props: {
    width: {
      type: [String, Number],
      default: 50,
    },
    height: {
      type: [String, Number],
      default: 50,
    },
    rectAttributes: {
      type: Object,
      required: true,
    },
    pathD: {
      type: String,
      required: true,
    },
    pathFill: {
      type: String,
      default: 'none',
    },
    pathStroke: {
      type: String,
      default: 'none',
    },
    extraPathD: {
      type: String,
      default: '',
    },
    extraPathFill: {
      type: String,
      default: '',
    },
  },
};
</script>